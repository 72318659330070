.master-container{

    width: 100%;
    min-height: 100svh;
    max-height: max-content;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


}

.bighead{

    width: 100%;
    /* height: 1080px; */
    position: relative;
    z-index: 1;
    overflow: hidden;

}

.abssvg{

    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
}
.abssvg2{

    position: absolute;
    /* background-color: var(--text); */
    top: 0;
    left: 0;
    z-index: 2;
    transition: all 0.25s linear;
    cursor: pointer;
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}
/* .abssvg2:hover{

    box-sizing: border-box;
    background-color: rgba(0,0,0,0);
    
} */

.gaussianb{

    width: 100%;
    z-index: -1;
    
}

.aboutContainer{
    
    width: 100%;
    min-height: 100svh;
    max-height: max-content;
    padding: 10vw;
    margin-top: 5rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    /* background-color: var(--bkg); */
    position: relative;

}

.aboutHeader{
    
    font-size: calc(128px + 0.5vw);
    font-family: 'OffBit', sans-serif;
    width: 100%;
    justify-content: left;
    text-align: left;
    z-index: 4;
    color: var(--text);

}

.aboutBody{
        
    font-size: calc(12px + 0.5vw);
    width: 50%;
    justify-content: left;
    margin-top: 2vh;
    text-align: left;
    z-index: 4;
    font-family: 'Helvetica Neue', sans-serif;
    color: var(--text);

}

.aboutFloatie{
    
    position: absolute;
    width: 100%;
    right: 0;
    z-index: 3;

}

.teamBanner{
    width: 100%;
    min-height: 30svh;
    max-height: max-content;
    padding: 10vw;
    margin-top: 5vh;
    margin-bottom: 5vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: right;
    justify-content: end;
    position: relative;
}

.teambubble{

    position: absolute;
    width: 50%;
    max-height: 60vh;
    margin-left: 10vw;
    left: 0;
    z-index: 3;
    
}

.teamMaster{
    width: 100%;
    
    overflow:hidden;
}

.teambubbleL{

    position: absolute;
    width: 100%;
    height: 200%;
    /* max-height: 40vh; */
    right: 0;
    z-index: -1;
    transform: translateX(50%);
    
}
.teambubbleR{

    position: absolute;
    width: 100%;
    height: 200%;

    /* max-height: 40vh; */
    left: 0;
    z-index: -1;
    transform: translateX(-50%);
    
}

.teamHeader{

    font-size: calc(128px + 0.5vw);
    font-family: 'OffBit', sans-serif;
    width: 80%;
    justify-content: left;
    text-align: right;
    z-index: 4;
    color: var(--text);

}

.circleIdentify{
    visibility: visible;
}

.teamContainer{
        
    width: 100%;
    min-height: 100svh;
    max-height: max-content;
    padding: 2vw 10vw 10vw 10vw;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    font-family: 'Helvetica Neue', sans-serif;
}
.teamCardL{
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    margin-bottom: 20vh;
    justify-content: center;
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: left;
    z-index: 5;
}
.teamCardR{
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    margin-bottom: 20vh;
    justify-content: center;
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: right;
    z-index: 5;
}
.teamCardHeader{
    font-size: calc(32px + 0.5vw);
    z-index: 4;
    width: 100%;
    color: var(--text);
    margin-right: 20px;
}
.teamCardBodyL{
    font-size: calc(10px + 0.5vw);
    width: 100%;
    padding-right: 50%;
    box-sizing: border-box;
    color: var(--text);
}
.teamCardBodyR{
    font-size: calc(10px + 0.5vw);
    width: 100%;
    padding-left: 50%;
    box-sizing: border-box;
    color: var(--text);
}
.teamCardExplrL{
    padding-top: 2vh;
    font-size: calc(10px + 0.5vw);
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-right: 50%;
    box-sizing: border-box;
    font-weight: bold;
    color: var(--text);
}
.teamCardExplrR{
    padding-top: 2vh;
    font-size: calc(10px + 0.5vw);
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-left: 50%;
    box-sizing: border-box;
    font-weight: bold;
    color: var(--text);
}
.textItem{
    width: 100%;
}
.spacer{
    width: 100%;
    height: 10vh;
}
.contactContainer{
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    column-gap: 15%;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    background-color: var(--tbkg);
    /* font-size: x-small; */
    font-family: 'Helvetica Neue', sans-serif;
}
.contactIcon{
    height: 16px;
}
.noFancy{
    font-size: 12px;
    text-decoration: none;
    box-sizing: border-box;
    color: var(--text);
    transition: all 0.1s linear;
}
.noFancy:hover{
    opacity: 0.5;
}

.applink{
    width: 100%;
    color: var(--text);
    transition: all 0.1s linear;
}
.applink:hover{
    opacity: 0.5;
}
.canvas{
    width: 100%;
    height: 100%;
    position: relative;
    /* top: 0;
    left: 0; */
    z-index: 1;

}
.current{
    position: relative;
    top: -100px;
    left: 0;
    z-index: 10;
    padding: 16px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    color: var(--text);
}

@media screen and (min-width: 800px) {
    .current{
        top: -64px;
    }
    .contactContainer{
        padding: 50px;
    }
    .noFancy{
        font-size: calc(16px + 0.5vw);
    }
    .aboutHeader{
        font-size: calc(250px + 0.5vw);
    }
    .teamHeader{
        font-size: calc(250px + 0.5vw);
    }
    .teamCardL{
        margin-bottom: 30vh;
    }
    .teamCardR{
        margin-bottom: 30vh;
    }
    .teamBanner{
        margin-top: 40vh;
        min-height: 70svh;

    }
    .contactIcon{
        height: 32px;
    }
    .teamCardHeader{
        font-size: calc(64px + 0.5vw);
    }
}