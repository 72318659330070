.main {
    width: 100%;
    height: 100svh;
    position: relative;

    overflow: hidden;
    color: var(--text);
    transition: filter 0.5s;
}

.title {
    position: absolute;
    left: 4rem;
    bottom: 3rem;
}

h1 {
    font-family: 'Alte Haas Grotesk';
    font-size: 48px;
    margin: 0;
    text-align: left;
    opacity: 0.5;
}

.galleryTitle {
    font-size: 96px;
    margin-top: -1rem;
    background: #ff105f;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: 1;
    animation: gradCycle 10s infinite;
}

@keyframes gradCycle {
    0% {
    background: #ff105f;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: 1;
    animation: gradCycle 10s infinite;
    }
    50% {
    background: #ffad06;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: 1;
    animation: gradCycle 10s infinite;
    }
    100% {
    background: #ff105f;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: 1;
    animation: gradCycle 10s infinite;
    }
    
}

.content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    transform-style: preserve-3d;
  	transform: perspective(1000px);
    height: 90%;
}

.item {
    width: 300px;
    height: 300px;
    margin: 1rem;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: box-shadow 0.5s;
    transition-delay: 0.2s;
}

.item:hover {
    box-shadow: 0 0px 30px rgb(255, 0, 132);
    animation: bshadowCycle 2s linear infinite;
}
@keyframes bshadowCycle {
    0%{
        box-shadow: 0 0px 30px rgb(255, 0, 132);

    }
    50%{
        box-shadow: 0 0px 30px rgb(255, 179, 0);

    }
    100%{
        box-shadow: 0 0px 30px rgb(255, 0, 132);


    }
}

.item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(0.8);
    transition: opacity 0.5s, transform 0.5s;
    pointer-events: none;
    opacity: 0.7;
}

.item:hover img {
    opacity: 1;
    transform: scale(1);
}

.item h2 {
    font-family: 'Alte Haas Grotesk';
    background-color: var(--tbkg);
    box-sizing: border-box;
    padding: 12px;
    font-weight: normal;
    font-size: 32px;
    opacity: 0;
    /* transform: translateY(-3.5rem); */
    transition: opacity 0.5s, transform 0.5s;
    pointer-events: none;
}

.item:hover h2 {
    opacity: 1;
    transform: translateY(-5.8rem);
}

.modal {
    position: fixed;
    bottom: 0;
    left: 23.5vw;
    height: 60%;
    width: 50vw;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    color: var(--bkg);
    background: var(--text);
    box-shadow: 0 0 60px rgb(139, 61, 207, 0.5);
    border-radius: 25px 25px 0 0;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    opacity: 0;
    z-index: 1000;
    transform: translateY(100px);
    transition: all 0.5s;
    animation: bshadowCycle 5s ease-in-out infinite;
}
@media screen and (max-width: 800px) {
    .modal{
        left: 8vw;
        width: 70vw;
    }
}

.modal.visible {
    pointer-events: all;
    opacity: 1;
    transform: translateY(0);
}

.modal h3 {
    font-family: 'Alte Haas Grotesk';
    text-align: left;
    font-size: 32px;
    margin: 0;
    margin-bottom: 2px;
}

.modal p {
    font-family: 'Helvetica';
    font-size: 14px;
    text-align: left;
    margin: 0;
    margin-bottom: 30px;
}

.modalImages {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(calc(70px + 10vmin), 100%), 1fr));
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    grid-gap: 30px;
    width: 100%;
}

.modalImages img {
    border-radius: 5px;
    width: 150px;
    height: 150px;
    object-fit: cover;
    overflow: hidden;
    transition: transform 0.5s;
}

.modalImages img:hover {
    transform: scale(1.1);
}
