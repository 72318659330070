:root {
  
  --light-bkg: #f5f5f5;
  --light-tbkg: #ffffff74;
  --light-text: #000000;

  --dark-bkg: #0e0e0e;
  --dark-tbkg: #0000007f;
  --dark-text: #ffffff;

  --bkg: var(--dark-bkg);
  --text: var(--dark-text);
  --tbkg: var(--dark-tbkg);
  
}

root [data-ctheme = "light"]{

  --bkg: var(--light-bkg);
  --text: var(--light-text);
  --tbkg: var(--light-tbkg);

}

root [data-ctheme = "dark"]{
  
  --bkg: var(--dark-bkg);
  --text: var(--dark-text);
  --tbkg: var(--dark-tbkg);

}

html {

  color: var(--bkg);
  background-color: var(--bkg);

}

body {
  margin: 0;
  height: 100%;
  font-family: 'Helvetica Neue', 'Syne Mono';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
