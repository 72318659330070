
.all {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100svw;
    height: 100%;
    overflow: hidden;
    background-color: rgba(0,0,0,0);
    color: var(--text);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    transition: all 0.5s linear;
}

.allshown {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100svw;
    height: 100%;
    overflow: hidden;
    background-color: var(--tbkg);
    color: var(--text);
    display: flex;
    visibility: show;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.5s linear;
}

.sidebar{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
    width: 0px;
    height: 100%;
    background-color: var(--bkg);
    color: var(--text);
    font-family: 'Helvetica';
    font-size: calc(32px + 0.5vw);
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    align-items: center;
    overflow: hidden;
    transition: all 0.5s linear;
}

.sidebarshown{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
    width: 500px;
    overflow: hidden;
    font-family: 'Helvetica';
    font-size: calc(32px + 0.5vw);
    height: 100%;
    background-color: var(--bkg);
    color: var(--text);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    transition: all 0.5s linear;
}

.sidebartrans{
    visibility: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* margin-left: 60%; */
    /* transform: translateX(-100%); */
    transition: all 0.2s linear;
}
.sidebartransshown{
    visibility: show;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* margin-left: 60%; */
    transition: all 0.2s linear;
}
.nofancy{
    text-decoration: none;
    color: var(--text);
    margin: 30px;
    transition: all 0.2s linear;
    /* width: 100%; */
    cursor: pointer;
}
.nofancy:hover{
    opacity: 0.5;
    /* background-color: var(--tbkg); */
}

.main{

    position: fixed;
    z-index: 999;
    /* z-index: 1002; */

    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 7svh;

    background-color: var(--bkg);
    color: var(--text);

}

.mainbelow{

    position: fixed;
    z-index: 999;
    /* z-index: 1002; */

    top: 7svh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    width: 100%;
    height: 1svh;

    background: linear-gradient(180deg, var(--bkg) 0%, var(--bkg) 0.01%, rgba(0,0,0,0) 100%);
    /* background-color: var(--bkg); */

}
.mainhidden{

    position: fixed;
    z-index: 1002;
    display: flex;
    top: 0;
    left: 0;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    height: 7svh;
    
    background-color: rgba(0,0,0,0);
    color: var(--text);

}

.burger{

    display: fixed;
    z-index: 1002;
    height: 100%;
    width: 7svh;
    padding: 2svh 2svh;
    box-sizing: border-box;
    cursor: pointer;

}

.clickOff{

    display: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 1000;
    background-color: rgba(0,0,0,0);
    
}
.clickOffhidden{

    display: none;
    visibility: hidden;

}
.switch{
    display: fixed;
    right: 0;
    z-index: 1003;
    height: 100%;
    width: 7svh;
    margin-right: 2svh;
    transition: all 0.5s linear;
    box-sizing: border-box;
    cursor: pointer;
}
.switchBallLight{
    transform: translateX(128px);
}
.switchBallDark{
    transform: translateX(0);
}
@media screen and (max-width: 800px) {
    .sidebarshown{
        width: 100%;
    }
    /* .main{
        height: 10svh;
    }
    .mainbelow{
        top: 10svh;
    } */
    .burger{
        width: 10svh;
    }
    .sidebar{
        font-size: calc(24px + 0.5vw);
    }
    .sidebarshown{
        font-size: calc(24px + 0.5vw);
    }
}